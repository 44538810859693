import {useTranslation} from 'i18n';

import {EmptyContent} from '~components/common/EmptyContent';
import {BaseLayout} from '~components/_layout/BaseLayout';

export default function NotFoundPage() {
  const {t} = useTranslation('common');

  return (
    <BaseLayout>
      <EmptyContent title={t('errorPageNotFound')} />
    </BaseLayout>
  );
}
