import {Box, Typography} from '@mui/material';

interface EmptyContentProps {
  title: string;
  description?: string;
  /**
   * Defaults to a generic empty folder image
   */
  imageSrc?: string;
  imgAlt?: string;
  children?: React.ReactNode;
}

export function EmptyContent({imageSrc, title, description, imgAlt = '', children}: EmptyContentProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        paddingTop: 2,
        paddingBottom: 20, // visually center the content
        gap: 2,
      }}
    >
      <img src={imageSrc || '/images/empty-generic.svg'} alt={imgAlt} width={600} height={450} />
      <Typography variant='h3'>{title}</Typography>
      {description && <Typography variant='body1'>{description}</Typography>}
      {children}
    </Box>
  );
}
