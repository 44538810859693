import {ReactNode} from 'react';

import {MainMenu} from '~components/header/MainMenu';
import {MainTitleBar, MainTitleBarProps} from '~components/header/MainTitleBar';
import {Container, LayoutContent, LayoutHeader, LayoutNav} from './layoutComponents';

export interface BaseLayoutProps extends MainTitleBarProps {
  children?: ReactNode;
  customContentAndFooter?: boolean;
}

export function BaseLayout({children, customContentAndFooter = false, ...titleBarProps}: BaseLayoutProps) {
  return (
    <Container>
      <LayoutNav>
        <MainMenu />
      </LayoutNav>

      <LayoutHeader>
        <MainTitleBar {...titleBarProps} />
      </LayoutHeader>
      {customContentAndFooter ? children : <LayoutContent>{children}</LayoutContent>}
    </Container>
  );
}
